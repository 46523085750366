<template>
  <button
    v-if="shouldShow"
    ref="open-premium-modal-button"
    class="premium-ad-banner"
    type="button"
    @click="openPremiumModal"
  >
    <span ref="message">{{ message }}</span>
    <ScalingPremiumBadge />
  </button>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ScalingPremiumBadge from './scaling-premium-badge.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';

@Component({
  components: {
    ScalingPremiumBadge,
  },
})
export default class PremiumAdBanner extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public get message(): string {
    if (!this.$subscription.hasEverHadSubscription) {
      return 'Start your free trial now';
    }

    if (this.$subscription.isTrial) {
      return this.trialMessage;
    }

    return 'Unlock more with';
  }

  public get shouldShow(): boolean {
    if (!this.$subscription.hasCurrentSubscriptionBeenInitialised) return false;
    if (this.$subscription.currentSubscriptionInfo?.isCancelling) return true;
    if (this.$subscription.isTrial) {
      return !this.$subscription.hasPaymentMethodSet;
    }

    return !this.$subscription.hasAnyPaidFeature;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
</script>

<style lang="scss" scoped>
button.premium-ad-banner {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  gap: $space-md;
  padding: $space-md $space-base;
  font-size: $font-size-sm;

  @include container-less-than(sm) {
    gap: $space-sm;
    padding: $space-sm $space-base;
    font-size: $font-size-xs;
  }
}
</style>
