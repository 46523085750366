import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScalingPremiumBadge = _resolveComponent("ScalingPremiumBadge")!

  return (_ctx.shouldShow)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        ref: "open-premium-modal-button",
        class: "premium-ad-banner",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPremiumModal && _ctx.openPremiumModal(...args)))
      }, [
        _createElementVNode("span", { ref: "message" }, _toDisplayString(_ctx.message), 513),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createVNode(_component_ScalingPremiumBadge)
      ], 512))
    : _createCommentVNode("", true)
}