
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {SubscriptionProduct} from '@reedsy/utils.subscription';

@Component({
  components: {
    ExpandTransition,
    SubscriptionProductPrice,
  },
})
export default class CompactFeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public expanded = false;

  public get enabledInfo(): string {
    let enabledInfo = 'Enabled';

    if (this.isRemovingAddon) {
      enabledInfo += ` until ${this.cancellationDate}`;
    }

    return enabledInfo;
  }

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get currentPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.products);
  }

  public get nextPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get product(): SubscriptionProduct {
    return this.summary.product;
  }

  public get isActive(): boolean {
    return this.$subscription.activeProducts.has(this.product);
  }

  public get isRemovingAddon(): boolean {
    return this.currentPeriodProducts.has(this.product) &&
      !this.nextPeriodProducts.has(this.product);
  }

  public get cancellationDate(): string {
    return this.currentSubscription.nextBilling.date;
  }

  public get isProduct(): boolean {
    return !!this.summary.product;
  }
}
