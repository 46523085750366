import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = {
  ref: "next-payment-info",
  class: "flex-centered"
}
const _hoisted_3 = {
  ref: "price-info",
  class: "flex-centered"
}
const _hoisted_4 = {
  key: 0,
  class: "info-text"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex-justified" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextSkeletonLoader = _resolveComponent("TextSkeletonLoader")!
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "bordered payment-info-panel no-gutter reedsy-accented neutral" }, {
    footer: _withCtx(() => [
      _createElementVNode("rbe-footer", _hoisted_6, [
        _createElementVNode("p", null, [
          _createElementVNode("a", {
            ref: "stripe-link",
            class: "link",
            target: "_blank",
            href: _ctx.billingPortalUrl,
            "aria-label": "Stripe dashboard"
          }, "Log in to the Stripe dashboard", 8, _hoisted_7),
          _cache[5] || (_cache[5] = _createTextVNode(" to manage your plan,\n          update payment methods and view invoices.\n        "))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("rbe-payment-info", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.nextPaymentInfoTitle), 1),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _createVNode(_component_TextSkeletonLoader, {
            class: "info-text",
            text: _ctx.subscription?.nextBilling.date
          }, null, 8, ["text"])
        ], 512),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Subscription", -1)),
          _cache[2] || (_cache[2] = _createTextVNode()),
          (_ctx.subscription?.isCancelling)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "\n          will be cancelled\n        "))
            : (_openBlock(), _createBlock(_component_SubscriptionPrice, {
                key: 1,
                class: "info-text",
                price: _ctx.subscription?.nextBilling.price,
                currency: _ctx.subscription?.currency,
                interval: _ctx.subscription?.interval
              }, null, 8, ["price", "currency", "interval"]))
        ], 512),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("a", {
          ref: "payment-settings-link",
          href: _ctx.billingPortalUrl,
          target: "_blank",
          class: "reedsy-accented button accent-primary"
        }, "\n        Payment settings\n      ", 8, _hoisted_5)
      ]),
      _cache[6] || (_cache[6] = _createTextVNode())
    ]),
    _: 1
  }))
}