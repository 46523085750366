
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import TextSkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/text-skeleton-loader.vue';

@Component({
  components: {
    Panel,
    TextSkeletonLoader,
    SubscriptionPrice,
  },
})
export default class PaymentInfo extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public get subscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get nextPaymentInfoTitle(): string {
    if (this.subscription?.isCancelling) return 'Cancellation date';
    return 'Next billing date';
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public async created(): Promise<void> {
    await this.$subscription.refreshCurrentSubscriptionInfo();
  }
}
