<template>
  <div
    class="feature-summary reedsy-accented"
    :class="{
      active: isProduct && selected,
      'being-removed': isRemovingAddon
    }"
  >
    <rbe-feature-header class="flex-justified">
      <div class="flex-top">
        <p class="enabled-title">
          {{ enabledInfo }}
        </p>
        <h2 class="flex-left">
          {{ summary.title }}
          <SubscriptionProductPrice
            class="price"
            :prices="prices"
            :product="summary.product"
            :interval="interval"
          />
        </h2>
      </div>

      <rbe-feature-toggle class="flex-center">
        <VuiToggle
          v-model="selected"
          class="label-left"
          :label="isActive && !isRemovingAddon ? 'Subscribed' : 'Add to subscription'"
        />

        <button
          type="button"
          @click="expanded = !expanded"
        >
          <component :is="expanded ? 'VuiIconUp' : 'VuiIconDown'" />
        </button>
      </rbe-feature-toggle>
    </rbe-feature-header>

    <ExpandTransition>
      <ul
        v-if="expanded"
        class="flex-top"
      >
        <li
          v-for="feature in summary.features"
          :key="feature"
        >
          {{ feature }}
        </li>
      </ul>
    </ExpandTransition>
    <template v-if="isRemovingAddon">
      <hr class="removal-info-separator">
      <rbe-removal-info>
        <p>
          This add-on was removed from your subscription and
          will be disabled at the start of your next billing cycle on
          {{ cancellationDate }}.
        </p>
      </rbe-removal-info>
    </template>
  </div>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import {SubscriptionProduct} from '@reedsy/utils.subscription';

@Component({
  components: {
    ExpandTransition,
    SubscriptionProductPrice,
  },
})
export default class CompactFeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public expanded = false;

  public get enabledInfo(): string {
    let enabledInfo = 'Enabled';

    if (this.isRemovingAddon) {
      enabledInfo += ` until ${this.cancellationDate}`;
    }

    return enabledInfo;
  }

  public get currentSubscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get currentPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.products);
  }

  public get nextPeriodProducts(): Set<SubscriptionProduct> {
    return new Set(this.currentSubscription?.nextBilling.products);
  }

  public get product(): SubscriptionProduct {
    return this.summary.product;
  }

  public get isActive(): boolean {
    return this.$subscription.activeProducts.has(this.product);
  }

  public get isRemovingAddon(): boolean {
    return this.currentPeriodProducts.has(this.product) &&
      !this.nextPeriodProducts.has(this.product);
  }

  public get cancellationDate(): string {
    return this.currentSubscription.nextBilling.date;
  }

  public get isProduct(): boolean {
    return !!this.summary.product;
  }
}
</script>

<style lang="scss" scoped>
.feature-summary {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-lg;
  border: $border-width-lg solid var(--reedsy-neutral);
  box-shadow: 0 $space-xxxs $space-xs var(--reedsy-shadowColor-muted);
  background-color: var(--reedsy-plain);
  width: 100%;

  &.being-removed {
    border-style: dashed;
  }

  &.active {
    border-color: var(--local-color-default);
  }

  > * {
    padding: $space-base;
  }

  .enabled-title {
    color: var(--local-color-default);
  }

  rbe-feature-toggle {
    gap: $space-base;

    @include screen-less-than(sm) {
      :deep(.vui-toggle) .toggle-label {
        display: none;
      }
    }
  }

  ul {
    border-top: $border-width-lg solid var(--reedsy-neutral);
    flex: 1 0 auto;
    gap: $space-sm;
    padding: 0 $space-base;

    li {
      @include font-family($controls);

      &:first-child {
        margin-top: $space-base;
      }

      &:last-child {
        margin-bottom: $space-base;
      }
    }
  }

  h2 {
    font-size: $font-size-lg;
    gap: $space-sm;

    .price {
      @include font-family($controls, normal);
    }
  }

  .removal-info-separator {
    padding: 0;
    height: $border-width;
    width: calc(100% - 2 * $space-base);
  }
}
</style>
