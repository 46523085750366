<template>
  <Panel class="bordered payment-info-panel no-gutter reedsy-accented neutral">
    <rbe-payment-info class="flex-justified">
      <div
        ref="next-payment-info"
        class="flex-centered"
      >
        <p>{{ nextPaymentInfoTitle }}</p>

        <TextSkeletonLoader
          class="info-text"
          :text="subscription?.nextBilling.date"
        />
      </div>

      <div
        ref="price-info"
        class="flex-centered"
      >
        <p>Subscription</p>

        <span
          v-if="subscription?.isCancelling"
          class="info-text"
        >
          will be cancelled
        </span>
        <SubscriptionPrice
          v-else
          class="info-text"
          :price="subscription?.nextBilling.price"
          :currency="subscription?.currency"
          :interval="subscription?.interval"
        />
      </div>

      <a
        ref="payment-settings-link"
        :href="billingPortalUrl"
        target="_blank"
        class="reedsy-accented button accent-primary"
      >
        Payment settings
      </a>
    </rbe-payment-info>

    <template #footer>
      <rbe-footer class="flex-justified">
        <p>
          <a
            ref="stripe-link"
            class="link"
            target="_blank"
            :href="billingPortalUrl"
            aria-label="Stripe dashboard"
          >Log in to the Stripe dashboard</a> to manage your plan,
          update payment methods and view invoices.
        </p>
      </rbe-footer>
    </template>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import TextSkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/text-skeleton-loader.vue';

@Component({
  components: {
    Panel,
    TextSkeletonLoader,
    SubscriptionPrice,
  },
})
export default class PaymentInfo extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public get subscription(): ICurrentSubscription {
    return this.$subscription.currentSubscriptionInfo;
  }

  public get nextPaymentInfoTitle(): string {
    if (this.subscription?.isCancelling) return 'Cancellation date';
    return 'Next billing date';
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public async created(): Promise<void> {
    await this.$subscription.refreshCurrentSubscriptionInfo();
  }
}
</script>

<style lang="scss" scoped>
$panel-padding: 0.5rem;
$panel-border-radius: 0.5rem;

@include screen-less-than(sm) {
  rbe-payment-info {
    flex-direction: column;
    gap: $space-base;
  }
}

.payment-info-panel {
  .info-text {
    font-size: $font-size-lg;

    @include font-family($controls, bold);

    :deep(.skeleton-loader) {
      margin: $space-xs 0;
    }
  }

  :deep(.panel-body) {
    padding: $space-xl $space-base;
  }
}

rbe-footer {
  background-color: var(--local-color-muted);
  padding: $space-base;
  border-bottom-left-radius: $panel-border-radius;
  border-bottom-right-radius: $panel-border-radius;

  p {
    font-size: $font-size-sm;
  }
}
</style>
