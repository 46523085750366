import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "reedsy-accented accent-premium flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentInfoPanel = _resolveComponent("PaymentInfoPanel")!
  const _component_TrialPanel = _resolveComponent("TrialPanel")!
  const _component_ManageAddonsPanel = _resolveComponent("ManageAddonsPanel")!

  return (_openBlock(), _createElementBlock("rbe-subscription-account-settings", _hoisted_1, [
    (_ctx.$subscription.hasAnyPaidFeature)
      ? (_openBlock(), _createBlock(_component_PaymentInfoPanel, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "section-title" }, "\n      Manage add-ons\n    ", -1)),
    _cache[3] || (_cache[3] = _createTextVNode()),
    (!_ctx.$subscription.hasEverHadSubscription)
      ? (_openBlock(), _createBlock(_component_TrialPanel, { key: 1 }))
      : (_openBlock(), _createBlock(_component_ManageAddonsPanel, { key: 2 })),
    _cache[4] || (_cache[4] = _createTextVNode()),
    (_ctx.$subscription.hasEverHadSubscription && !_ctx.$subscription.hasAnyPaidFeature)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "button reedsy-accented",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPremiumModal && _ctx.openPremiumModal(...args)))
        }, "\n      Go Premium\n    "))
      : _createCommentVNode("", true)
  ]))
}