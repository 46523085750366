import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';

@injectable()
export class LoadSubscriptionInfoPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(SharedStoreName.Subscription)
  public readonly _subscription: SharedSubscriptionModule;

  public override async afterEach(): Promise<void> {
    await this._subscription.initialise();
  }
}
