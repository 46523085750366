
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import Navigation from '@reedsy/studio.shared/services/navigation';
import TrialPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/trial-panel.vue';
import PaymentInfoPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/payment-info-panel.vue';
import ManageAddonsPanel from '@reedsy/studio.home.bookshelf/components/subscription-settings/manage-addons-panel.vue';

@Component({
  components: {
    TrialPanel,
    PaymentInfoPanel,
    ManageAddonsPanel,
  },
})
export default class SubscriptionAccountSettings extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public openPremiumModal(): void {
    this.$subscriptionModal.open();
  }
}
