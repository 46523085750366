import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumBadgeInfo = _resolveComponent("PremiumBadgeInfo")!
  const _component_PremiumBadge = _resolveComponent("PremiumBadge")!
  const _component_InfoTip = _resolveComponent("InfoTip")!

  return (_ctx.config.features.subscriptions)
    ? (_openBlock(), _createBlock(_component_InfoTip, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)), {
        trigger: _withCtx(() => [
          _renderSlot(_ctx.$slots, "trigger", {}, () => [
            _createVNode(_component_PremiumBadgeInfo)
          ], true)
        ]),
        default: _withCtx(({hide}) => [
          _createElementVNode("rbe-premium-info", null, [
            _createVNode(_component_PremiumBadge),
            _cache[0] || (_cache[0] = _createTextVNode()),
            _createElementVNode("rbe-premium-copy", null, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _cache[1] || (_cache[1] = _createTextVNode()),
            (!_ctx.$subscription.hasFeature(_ctx.paidFeature))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  ref: "freeTrialButton",
                  class: "free-trial-button",
                  onClick: ($event: any) => {_ctx.$subscriptionModal.open(); hide()}
                }, "\n          Start your free trial →\n        ", 8, _hoisted_1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }, 16))
    : _createCommentVNode("", true)
}